import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import axios from 'axios';

// components
import Footer from '../components/Footer/Footer';

import Header from '../components/Header/Header';

import BarChart from '../components/BarChart';
import MagHistogram from '../components/MagHistogram';
import ProvisionalCodeInformationTable from '../components/ProvisionalCodeInformationTable/ProvisionalCodeInformationTable';
import OrbitInfoScatterPlot from '../components/OrbitInfoScatterPlot/OrbitInfoScatterPlot';
import { useTranslation } from 'react-i18next';

const apiURL = process.env.REACT_APP_API_URL;

const DataAnalysis = () => {
  const { t } = useTranslation();

  const [rankingData, setRankingData] = useState([]);
  const [rankingWeeklyData, setRankingWeeklyData] = useState([]);

  const [NNewObject, setNNewObject] = useState(-1);

  const [magHistogramData, setMagHistogramData] = useState([]);

  const getRanking = () => {
    axios
      .get(`${apiURL}/measure_ranking`)
      .then((res) => {
        const rankingData = res.data.result;

        if (rankingData) {
          setRankingData(rankingData);
        }
      })
      .catch((e) => console.log(e));
  };

  const getRankingWeekly = () => {
    axios
      .get(`${apiURL}/measure_ranking?is_weekly=True`)
      .then((res) => {
        const rankingWeeklyData = res.data.result;

        if (rankingWeeklyData) {
          setRankingWeeklyData(rankingWeeklyData);
        }
      })
      .catch((e) => console.log(e));
  };

  const getObjectsFigure = () => {
    axios
      .get(`${apiURL}/N_new_objects`)
      .then((res) => {
        const N_new_objects = res.data.result;

        if (N_new_objects) {
          setNNewObject(N_new_objects);
        }
      })
      .catch((e) => console.log(e));
  };

  const getMagHistogram = () => {
    axios
      .get(`${apiURL}/mag_histogram`)
      .then((res) => {
        const data = res.data.result;

        if (data) {
          setMagHistogramData(data);
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getRanking();
    getRankingWeekly();

    getObjectsFigure();

    getMagHistogram();
  }, []);

  return (
    <div className="l-page bg-img">
      <Header />
      <div className="page_wrap">
        <div className="u-page_sub_title bg-color_transparent">
          <h3 className="f-page_title f-c_white">{t('データ解析状況')}</h3>
        </div>
        <div className="u-contents_main bg-color_white">
          <div className="contents_text">
            <div className="data_analysis">
              <p className="data_analysis-title">
                {t('COIASで現在までに発見された新天体候補数')}
              </p>
              <p className="number_wrap">
                <span className="number">{NNewObject}</span>
                <span className="unit">{t('個')}</span>
              </p>
            </div>
            <div className="data_analysis">
              <span className="mg-r_xs f-s14_w500">
                {t(
                  '(注) 本ページの以下に記載しているデータの更新は1日おきです。リアルタイムの数字ではないためご注意ください。',
                )}
              </span>
            </div>
            <div className="data_analysis">
              <p className="data_analysis-title">{t('測定天体数ランキング')}</p>
              <BarChart rankingData={rankingData} />
            </div>
            <div className="data_analysis">
              <p className="data_analysis-title">
                {t('直近1週間の測定天体数ランキング')}
              </p>
              <BarChart rankingData={rankingWeeklyData} />
            </div>
            <div className="data_analysis">
              <p className="data_analysis-title">
                {t('COIASで発見された新天体候補数の履歴')}
              </p>
              <img
                src="https://web-coias.u-aizu.ac.jp/images/new_object_history.png"
                alt=""
                width="100%"
                height="100%"
              />
            </div>
            <div className="data_analysis">
              <p className="data_analysis-title">
                {t('COIAS測定天体の等級ヒストグラム')}
              </p>
              <MagHistogram magHistogramData={magHistogramData} />
              <span className="mg-r_xs f-s14_w500 glossary_wrap-text">
                {t('「確定番号天体」「仮符号天体」の意味については')}
                <NavLink
                  to="/details/procedure"
                  style={{ textDecoration: 'underline' }}
                >
                  {t('「小惑星を発見するには？」')}
                </NavLink>
                {t('のページをご覧ください。')}
              </span>
              <br />
              <span className="mg-r_xs f-s14_w500">
                {t(
                  '(注) ヒストグラムに記載した直径はあくまでも目安です (小惑星の太陽からの距離2.5au、反射率0.15、位相角0度を仮定した見積もり)。またフィルターの違いは考慮しておらず、様々なフィルターで得られた等級を使用しています。',
                )}
              </span>
            </div>
            <div className="data_analysis">
              <p className="data_analysis-title">
                {t('COIASで発見された仮符号のリスト')}
              </p>
              <ProvisionalCodeInformationTable />
              <br />
              <span className="mg-r_xs f-s14_w500">
                {t(
                  '(注) このリストでは情報の重複表示を避けるため、COIAS内で取得した仮符号同士が同じものと同定された場合、1つのみを残して他は記載しておりません。',
                )}
              </span>
            </div>
            <div className="data_analysis">
              <p className="data_analysis-title">
                {t('COIASで発見された仮符号天体の軌道情報')}
              </p>
              <OrbitInfoScatterPlot />
              <br />
              <span className="mg-r_xs f-s14_w500 glossary_wrap-text">
                {t(
                  'データ点にカーソルをあわせると各仮符号天体の軌道詳細が表示されます。スクロールとグラブでグラフの描画範囲を変更できます。',
                )}
              </span>
              <br />
              <span className="mg-r_xs f-s14_w500">
                {t('(注) 直径の見積もりには反射率0.15を仮定しています。')}
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default DataAnalysis;
