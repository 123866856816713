import React from 'react';

import './ModalStyle.scss';

// plugin
import { Button, Modal } from 'react-bootstrap';

const ImportantNoticeModal = ({ show, onHide }) => {
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>お知らせ / Notice</Modal.Title>
        </Modal.Header>
        <Modal.Body className="contents-text_only">
          <p>
            会津大学の計画停電と多少のメンテナンスのため、9月5日（木）18:00 -
            9月10日（火）9:00 の間COIASを停止致します。
            期間中、COIASをお使いいただけませんのでご了承ください。
          </p>
          <br />
          <p>
            For a planned power outage of University of Aizu and a trivial
            maintenance, we will stop COIAS from September 5th (Thu.) 18:00 JST
            - 10th (Tue.) 9:00 JST. Thank you for your cooperation in advance.
          </p>
        </Modal.Body>
        <Modal.Footer className="one_button">
          <Button variant="secondary" onClick={onHide}>
            閉じる / Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ImportantNoticeModal;
